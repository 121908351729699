import React, { useEffect, useState, useRef } from 'react';

const faqs = [
  {
    id: 1,
    question: 'K čemu je dobré zobrazování příchozích zpráv na telefonním čísle online?',
    answer: 'Soukromí: když používáte dočasné číslo, můžete ochránit své skutečné telefonní číslo před neznámými lidmi nebo službami, které by ho mohly zneužít ke spamu nebo jiným nevhodným účelům.'
  },
  {
    id: 2,
    question: 'Je bezpečné nechat si na toto telefonní číslo posílat osobní zprávy nebo něco důležitého?',
    answer: 'Určitě ne. Příchozí zprávy se zobrazují všem návštěvníkům tohoto webu. Zprávy tedy nejsou soukromé. Zprávy se zároveň neukládají, takže pokud refreshnete stránku, zprávy se ztratí.'
  },
  {
    id: 3,
    question: 'Je možné si nechat doručit zprávu a vyzvednout si ji později nebo ji poslat na jiné číslo (e-mail)?',
    answer: 'Ne. Příchozí zprávy se zobrazují jen zde na této stránce. Pokud byste si chtěli zprávu doručit na jiné číslo nebo ji přeposlat e-mailem, budete muset využít jinou službu (např. smsmanager.cz.).'
  },
  {
    id: 4,
    question: 'Musím po příjmu SMS refreshnout stránku?',
    answer: 'Ne. Příchozí zprávy se v reálném čase. Nemusíte znovu načítat stránku. Naopak: po načtení stránky se zprávy ztratí a obdržíte jiné telefonní číslo.'
  },

]

function App() {
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [number, setNumber] = useState('...'); // Placeholder number, replace as needed
  const wsRef = useRef(null); // Reference to store the WebSocket instance
  const pingIntervalRef = useRef(null); // Reference to store the ping interval ID
  const reconnectAttemptsRef = useRef(0); // Reference to store the reconnection attempts count
  const pingInterval = 30000; // 30 seconds

  const connectWebSocket = () => {
    wsRef.current = new WebSocket('wss://z33of5hche.execute-api.eu-central-1.amazonaws.com/v1');

    wsRef.current.onopen = () => {
      console.log('WebSocket connection opened');
      setIsConnected(true);
      reconnectAttemptsRef.current = 0; // Reset reconnection attempts on successful connection
      wsRef.current.send(JSON.stringify({ action: 'connected' }));
      document.getElementById('loading').style.display = 'none';
      document.getElementById('messages').style.display = 'block';
      document.getElementById('error').style.display = 'none';

      pingIntervalRef.current = setInterval(() => {
        wsRef.current.send(JSON.stringify({ action: 'ping' }));
      }, pingInterval);
    };

    wsRef.current.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      console.log(messageData);
      if (messageData.number) {
        setNumber("+" + messageData.number); // Set the connected number
      } else {
        const messageDiv = {
          id: messages.length,
          sender: messageData.sender || 'Unknown',
          time: new Date().toLocaleTimeString(),
          text: messageData.message
        };
        setMessages((prevMessages) => [...prevMessages, messageDiv]);
      }
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
      clearInterval(pingIntervalRef.current);
      document.getElementById('loading').style.display = 'block';
      document.getElementById('messages').style.display = 'none';
      document.getElementById('error').style.display = 'none';

      // Attempt reconnection with an exponential backoff
      const maxAttempts = 10;
      if (reconnectAttemptsRef.current < maxAttempts) {
        const delay = Math.min(1000 * 2 ** reconnectAttemptsRef.current, 30000); // exponential backoff, max 30s
        reconnectAttemptsRef.current += 1;
        setTimeout(() => {
          console.log(`Attempting to reconnect... (${reconnectAttemptsRef.current}/${maxAttempts})`);
          connectWebSocket();
        }, delay);
      } else {
        console.error('Max reconnection attempts reached. Giving up.');
        document.getElementById('error').style.display = 'block';
        document.getElementById('loading').style.display = 'none';
        document.getElementById('messages').style.display = 'none';
      }
    };
  };

  useEffect(() => {
    connectWebSocket();

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs only once


  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-4xl mx-auto">
        {/* Headline for USP */}
       
        <div className="px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Dočasné telefonní číslo</h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Ideální, pokud potřebujete jednorázově přijmout ověřovací zprávu.
            </p>
          </div>
        </div>

        <h2 className="w-full md:w-1/2 lg:w-1/2 m-auto text-xl font-semibold text-gray-600 mb-2">
          Příchozí zprávy
        </h2>
      
        {/* Messages Box */}
        <div className="flex justify-center mb-10" id="messages" style={{ display: 'none' }}>
          <div className="w-full md:w-1/2 lg:w-1/2 bg-white shadow-lg rounded-lg p-4 m-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-2" id="number">
              Číslo: {number}
            </h2>

            <div className="space-y-2">
              {messages.length === 0 ? (
                <div className="no-messages p-3 pl-0 rounded-md flex items-start justify-start">
                  <svg
                    className="animate-spin h-4 w-4 mr-1 text-gray-500"
                    style={{ display: 'inline' }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <p className="text-sm text-gray-400">Čekám na první příchozí zprávu.<br />Zpráva je po odeslání zobrazena zde do cca 5 vteřin</p>
                </div>
              ) : (
                <>
                  {messages.map((msg) => (
                    <div key={msg.id} className="message bg-gray-50 p-3 rounded-md">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm font-semibold text-gray-800">{msg.sender}</span>
                        <span className="text-xs text-gray-500">{msg.time}</span>
                      </div>
                      <p className="text-sm text-gray-600">{msg.text}</p>
                    </div>
                  ))}
                    <div className="no-messages p-3 pl-0 rounded-md flex items-start justify-start">
                      <svg
                        className="animate-spin h-4 w-4 mr-1 text-gray-500"
                        style={{ display: 'inline' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <p className="text-sm text-gray-400">Čekám na další zprávu</p>
                    </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Connecting Box */}
        <div className="flex justify-center mb-10" id="loading">
          <div className="w-full md:w-1/2 lg:w-1/2 bg-white shadow-lg rounded-lg p-4 flex items-center justify-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="text-lg font-semibold text-gray-800">Připojuji se k telefonnímu číslu...</span>
          </div>
        </div>

        <div className="flex justify-center mb-10" id="error" style={{ display: 'none' }}>
          <div className="w-full md:w-1/2 lg:w-1/2 bg-white shadow-lg rounded-lg p-4 flex items-center justify-center">
            <span className="text-lg font-semibold text-gray-800">Došlo k chybě připojení. Znovu načtěte stránku.</span>
          </div>
        </div>

        {/* Alert Box */}
        <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 rounded-md"
          role="alert"
        >
          <p className="font-bold">Pozor</p>
          <p>
            Příchozí zprávy se neukládají. Jen když máte tuto stránku otevřenou, tak můžete zobrazit nové příchozí zprávy. <strong>Zprávy se zobrazují všem návštěvníkům tohoto webu. Zprávy tedy nejsou soukromé.</strong> Je zakázáno používat službu k nelegálním účelům.
          </p>
        </div>

        <div className="">
          <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">FAQ</h2>
            <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                  <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default App;
